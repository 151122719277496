import React, { useEffect, useState } from 'react';
import {Input, Button, List, Message, Loader, ListIcon } from 'semantic-ui-react';
import { consulta, respuestaWEB, registra } from './funciones.js'; 

const ChatRoom = () => {
    let intervalo = null;
    const [texto, setTexto] = useState('');
    const [informes, setInformes] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        registra();
        enviaConsulta('listaInformes');
        console.log("ChatRoom component mounted");        
    }, []);

    const MuestraError = (error) => {
        console.log(error);
        setError('Ocurrió un error al enviar la consulta.');
        // Limpia el mensaje de error después de 5 segundos
        setTimeout(() => setError(''), 5000);
    }

    const funcionOKResp = (res) => {
        setLoading(false);
        clearInterval(intervalo);
        console.log(res);
        //Comprueba si en la respuesta viene un error
        if (res.error) {
            console.log(res.error);
            MuestraError(res.error);
            return;
        }
        if (res.PROBLEMAS) {
            console.log(res.PROBLEMAS[0]);
            MuestraError(res.PROBLEMAS[0]);
            return;
        }
        //Comprueba si es un archivo    
        if (res.rutas) {
            //Muestra el archivo en una nueva ventana
            window.open(res.rutas);
            //Obtiene el nombre del archivo desde la ruta
            const nombre = res.rutas.split('/').pop();
            //Añade el archivo a la lista de informes
            setInformes([...informes, nombre]);
        }    
        //Comprueba si es un listado de informes
        if (res.informes) {
            console.log(res.informes);
            //Divide la respuesta en un array y solo pone aquellos que empiezan por 'resumen'
            setInformes(res.informes.split(',').filter(informe => informe.startsWith('resumen')));
        }
    }

    const funcionErrorResp = (error) => {
        setLoading(false);
        clearInterval(intervalo);
        console.log(error);
        MuestraError(error);
    }

    const consultaRespuesta = async (id) => {
        const data = 'hola';
        let res = await respuestaWEB(data,id,funcionOKResp,funcionErrorResp);
        console.log(res);
    }

    const funcionOK = (res) => {
        setLoading(false);
        console.log(res);
    }

    const funcionError = (error) => {
        setLoading(false);
        console.log(error);
        MuestraError(error);
    }

    //Envia una consulta al servidor para ejecutar una función python
    const enviaConsulta = async (funcion) => {
        setLoading(true);
        try {
            // Aquí puedes incluir la información a enviar al servidor
            const data = { texto }; // Ajusta esto según necesites
            const uniqueId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            let res = await consulta(funcion, JSON.stringify(data), uniqueId, funcionOK, funcionError);
            intervalo = setInterval(() => { consultaRespuesta(uniqueId); }, 500);
            console.log(res);
        } catch (error) {
            console.error('Error al enviar al servidor:', error);
            MuestraError('Error al enviar al servidor');
        }
    };

    const handleItemClick = (nombre) => {
        const url = `http://helpbed.com/versiones/${nombre}`;
        window.open(url, '_blank');
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            enviaConsulta('informeAccion');
        }
    };

    return (
        <div>
            <h1>Acciones</h1>
            {error && <Message negative>{error}</Message>}
            <Input 
                type="text" 
                value={texto} 
                onChange={(e) => setTexto(e.target.value)} 
                onKeyPress={handleKeyPress}
                placeholder="Acción a consultar" 
            />
            <Button onClick={() => enviaConsulta('informeAccion')}>Informe</Button>
            {loading && <Loader active inline='centered' />}
            <List divided relaxed style={{ paddingTop: '2em', margin: '0 auto', maxWidth: '350px' }}>
                {informes.map((informe, index) => (
                    <List.Item key={index} onClick={() => handleItemClick(informe)}>
                        <ListIcon name='file' />
                        <List.Content>
                            <List.Header>{informe}</List.Header>
                        </List.Content>
                    </List.Item>
                ))}
            </List>
        </div>
    );
}

export default ChatRoom;